import React, { useState } from "react";

/* Form */
import { Controller, useForm } from "react-hook-form";

/* Material UI */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Switch,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
/* Style */
import { useStyles } from "../styles";

/* Hooks */
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { useSettingDispatchChange } from "../index";
import { VALET_DELIVERY_METHOD } from "../../../../constants";

const ValetAreaDeliverySettingsForm = ({
  entityID,
  settingValues,
  onAutoDeliveryAfterRequestSelected,
}) => {
  const classes = useStyles();
  const [areSettingsExpanded, setAreSettingsExpanded] = useState(true);
  const [showSettingChangeMessage, setShowSettingChangeMessage] = useState(
    false
  );
  const { dispatchBulkSettings } = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();

  const toggleSettingsExpanded = () => {
    setAreSettingsExpanded(!areSettingsExpanded);
  };
  const { control, setValue } = useForm({
    defaultValues: {
      showvaletareadelivery: settingValues.showValetAreaDelivery,
      valetareadeliverymethod: settingValues.valetareadeliverymethod,
    },
  });

  const handleBulkSettingsChange = async (
    settingarray,
    values,
    entityID,
    defaultValue,
    targetName
  ) => {
    if (!(await dispatchBulkSettings(settingarray, values, entityID))) {
      setValue(targetName, defaultValue);
      enqueueSnackbar("Unable to change Delivery Settings", {
        variant: "error",
        tag: "DeliverySettingChangeError",
      });
      return false;
    }
    return true;
  };

  const handleShowDeliverySelectionChanged = async (e) => {
    let targetName = "valetarea.showdelivery";
    const targetValue = e.target.checked;
    const settingArray = [targetName];
    const values = { "valetarea.showdelivery": targetValue };

    if (targetValue === settingValues.showValetAreaDelivery) return;

    if (
      !(await handleBulkSettingsChange(
        settingArray,
        values,
        entityID,
        settingValues.showValetAreaDelivery,
        "showvaletareadelivery"
      ))
    ) {
      return;
    }
  };

  const handleValetAreaDeliveryMethodChange = async (e) => {
    let targetName = "valetarea.deliverymethod";
    let retrievalSettingName = "valetarea.autoretrieve";
    const targetValue = parseInt(e.target.value);
    const settingArray = [targetName];
    const values = { "valetarea.deliverymethod": targetValue };

    if (targetValue === settingValues.valetareadeliverymethod) return;

    if (
      targetValue ===
      VALET_DELIVERY_METHOD.AUTO_DELIVERY_AFTER_REQUEST &&
      settingValues.autoRetrieveVehicleAfterRequest === false
    ) {
      settingArray.push(retrievalSettingName);
      values["valetarea.autoretrieve"] = true;

      if (
        !(await handleBulkSettingsChange(
          settingArray,
          values,
          entityID,
          settingValues.valetareadeliverymethod,
          "valetareadeliverymethod"
        ))
      ) {
        return;
      }

      onAutoDeliveryAfterRequestSelected();
      setShowSettingChangeMessage(true);
    } else {
      if (
        !(await handleBulkSettingsChange(
          settingArray,
          values,
          entityID,
          settingValues.valetareadeliverymethod,
          "valetareadeliverymethod"
        ))
      ) {
        return;
      }

      setShowSettingChangeMessage(false);
    }
  };

  return (
    <Grid
      container
      className={classes.panelRoot}
      data-testid="delivery-settings-container"
    >
      <Accordion
        data-tag="delivery-settings-accordion"
        expanded={areSettingsExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleSettingsExpanded}
        >
          <Typography className={classes.heading}>Delivery</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="showvaletareadelivery"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    role="valetarea-showdelivery-toggle"
                    label="Show Delivery on Shift Session"
                    control={
                      <Switch
                        {...field}
                        id="valetareashowdelivery"
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          handleShowDeliverySelectionChanged(e);
                        }}
                        checked={field.value}
                        data-checked={field.value}
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Add the "Delivery Method" label */}
              <Typography variant="h6">Delivery Method</Typography>
              <Controller
                name="valetareadeliverymethod"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      handleValetAreaDeliveryMethodChange(e);
                    }}
                  >
                    <FormControlLabel
                      value={String(VALET_DELIVERY_METHOD.MANUAL_DELIVERY)}
                      control={<Radio color="primary" />}
                      label="Manual Delivery"
                    />
                    <FormControlLabel
                      value={String(VALET_DELIVERY_METHOD.AUTO_DELIVERY_AFTER_REQUEST)}
                      control={<Radio color="primary" />}
                      label="Automatic Delivery After Request"
                    />
                    <FormControlLabel
                      value={String(VALET_DELIVERY_METHOD.AUTO_DELIVERY_AFTER_RETRIEVAL)}
                      control={<Radio color="primary" />}
                      label="Automatic Delivery After Retrieval"
                    />
                  </RadioGroup>
                )}
              />
              <div
              className={
                showSettingChangeMessage
                  ? classes.visibleMessage
                  : classes.hiddenMessage
              }
              >
                <Typography>
                  Automatic Retrieval After Request setting enabled
                </Typography>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ValetAreaDeliverySettingsForm.defaultProps = {
  settingValues: {},
};

export default ValetAreaDeliverySettingsForm;