import React, { useState } from "react";

/* Form */
import { Controller, useForm } from "react-hook-form";

/* Material UI */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/* Style */
import { useStyles } from "../styles";

/* Hooks */
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { useSettingDispatchChange } from "../index";
import { VALET_DELIVERY_METHOD } from "../../../../constants";

const ValetAreaRetrievalSettingsForm = ({
  entityID,
  settingValues,
  onManualRetrievalSelected
}) => {
  const classes = useStyles();
  const [areSettingsExpanded, setAreSettingsExpanded] = useState(true);
  const { dispatchBulkSettings } = useSettingDispatchChange();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [showSettingChangeMessage, setShowSettingChangeMessage] = useState(
    false
  );

  const toggleSettingsExpanded = () => {
    setAreSettingsExpanded(!areSettingsExpanded);
  };

  const {
    control, setValue } = useForm({
      defaultValues: {
        showvaletarearetrieval: settingValues.showValetAreaRetrieval,
        autoRetrieveVehicleAfterRequest: settingValues.autoRetrieveVehicleAfterRequest,
      }
    });

  const handleBulkSettingsChange = async (
    settingarray,
    values,
    entityID,
    defaultValue,
    targetName
  ) => {
    if (!(await dispatchBulkSettings(settingarray, values, entityID))) {
      setValue(targetName, defaultValue);
      enqueueSnackbar("Unable to change Retrieval Settings", {
        variant: "error",
        tag: "RetrievalSettingChangeError",
      });
      return false;
    }
    return true;
  };

  const handleShowRetrievalSelectionChanged = async (e) => {
    let targetName = "valetarea.showretrieval";
    const targetValue = e.target.checked;
    const settingArray = [targetName];
    const values = { "valetarea.showretrieval": targetValue };

    if (targetValue === settingValues.showValetAreaRetrieval) return;

    if (
      !(await handleBulkSettingsChange(
        settingArray,
        values,
        entityID,
        settingValues.showValetAreaRetrieval,
        "showvaletarearetrieval"
      ))
    ) {
      return;
    }
  };

  const handleAutoRetrieveSelectionChanged = async (e) => {
    let targetName = "valetarea.autoretrieve";
    let deliverySettingName = "valetarea.deliverymethod";
    const targetValue = e.target.checked;
    const settingArray = [targetName];
    const values = { "valetarea.autoretrieve": targetValue };

    if (targetValue === settingValues.autoRetrieveVehicleAfterRequest) return;

    if (
      targetValue === false &&
      settingValues.valetareadeliverymethod ==
      VALET_DELIVERY_METHOD.AUTO_DELIVERY_AFTER_REQUEST
    ) {
      settingArray.push(deliverySettingName);
      values["valetarea.deliverymethod"] =
      VALET_DELIVERY_METHOD.MANUAL_DELIVERY;

      if (
        !(await handleBulkSettingsChange(
          settingArray,
          values,
          entityID,
          settingValues.autoRetrieveVehicleAfterRequest,
          "autoRetrieveVehicleAfterRequest"
        ))
      ) {
        return;
      }
      onManualRetrievalSelected();
      setShowSettingChangeMessage(true);
    } else {
      if (
        !(await handleBulkSettingsChange(
          settingArray,
          values,
          entityID,
          settingValues.autoRetrieveVehicleAfterRequest,
          "autoRetrieveVehicleAfterRequest"
        ))
      ) {
        return;
      }
      setShowSettingChangeMessage(false);
    }
  };

  return (
    <Grid
      container
      className={classes.panelRoot}
      data-testid="retrieval-settings-container"
    >
      <Accordion
        data-tag="retrieval-settings-accordion"
        expanded={areSettingsExpanded}
        TransitionProps={{
          unmountOnExit: true,
        }}
        className={classes.fullWidthAccordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleSettingsExpanded}
        >
          <Typography className={classes.heading}>
            Retrieval
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="showvaletarearetrieval"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    role="valetarea-showretrieval-toggle"
                    label="Show Retrieval on Shift Session"
                    control={
                      <Switch
                        {...field}
                        id="valetareashowretrieval"
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          handleShowRetrievalSelectionChanged(e);
                        }}
                        checked={field.value}
                        data-checked={field.value}
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="autoRetrieveVehicleAfterRequest"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    role="valetarea-autoretrieve-toggle"
                    label="Automatic Retrieval After Request"
                    control={
                      <Switch
                        {...field}
                        id="valetareaautoretrieve"
                        color="primary"
                        onChange={(e)=> {
                          field.onChange(e.target.checked);
                          handleAutoRetrieveSelectionChanged(e);
                        }}
                        checked={field.value}
                        data-checked={field.value}
                      />
                    }
                  />
                )}
              />
              <div
                className={
                  showSettingChangeMessage
                    ? classes.visibleMessage
                    : classes.hiddenMessage
                }
              >
                <Typography>
                    Delivery Method setting changed to manual delivery
                </Typography>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

ValetAreaRetrievalSettingsForm.defaultProps = {
  settingValues: {},
};

export default ValetAreaRetrievalSettingsForm;